import React, { Component } from 'react'
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import "./WaitingStyle.css"
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import InfoIcon from '@material-ui/icons/Info';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import VideocamIcon from '@material-ui/icons/Videocam';
import MicIcon from '@material-ui/icons/Mic';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VideoRoom from './components/VideoRoomComponent';
import {Link} from "react-router-dom";
import axios from 'axios';
import UserModel from './models/user-model';
import IconButton from '@material-ui/core/IconButton';
import Mic from '@material-ui/icons/Mic';
import MicOff from '@material-ui/icons/MicOff';
import Videocam from '@material-ui/icons/Videocam';
import VideocamOff from '@material-ui/icons/VideocamOff';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';

var localUser = new UserModel();
const styles = theme => createStyles({
    join: {
        backgroundColor: '#f0ad4e',
        borderRadius: 3,
        border: 1,
        color: 'Black',
        height: 40,
        padding: '0 30px',
        borderColor: "#f0ad4e"
      },
    closeButton: {
        textTransform: 'capitalize',
        fontWeight: 'normal',
        color: "white"
    },
    userIcon: {
        height: '9rem',
        width: '9rem',
        color: "gray"
    },
    userImageBox: {
        textAlign: "center"
    },
    iconBox: {
        textAlign: "center" 
    },
    icon: {
        height: '3rem',
        width: '3rem',
        color: "white",
        backgroundColor: "#3f3e3e",
        fontSize: '1.2rem',
        borderRadius: '7px',
        padding: '10px'
    },
    iconText: {
        textAlign: "center",
        fontSize: '0.7rem'
    },
})

// const useStyles = makeStyles((theme) => ({
//     join: {
//         backgroundColor: '#f0ad4e',
//         borderRadius: 3,
//         border: 1,
//         color: 'Black',
//         height: 40,
//         padding: '0 30px',
//         borderColor: "#f0ad4e"
//       },
//     closeButton: {
//         textTransform: 'capitalize',
//         fontWeight: 'normal',
//         color: "white"
//     },
//     userIcon: {
//         height: '9rem',
//         width: '9rem',
//         color: "gray"
//     },
//     userImageBox: {
//         textAlign: "center"
//     },
//     iconBox: {
//         textAlign: "center" 
//     },
//     icon: {
//         height: '3rem',
//         width: '3rem',
//         color: "white",
//         backgroundColor: "#3f3e3e",
//         fontSize: '1.2rem',
//         borderRadius: '7px',
//         padding: '10px'
//     },
//     iconText: {
//         textAlign: "center",
//         fontSize: '0.7rem'
//     },
   
//   }));

// function Waiting(props) {
//     const [join, setjoin] = useState(false);
//     const classes = useStyles();
//     let location = useLocation();
//     const changeState = () => {
//         setjoin(true);
//     }
//     console.log(location);
//     return (
//         <div class="app">
//             <Grid container>
//                 <Grid item xs={10}></Grid>
//                 <Grid item xs={2}>
//                     <Box mt={5}><Link to="/"><Button variant="outlined" className={classes.closeButton} >Close</Button></Link></Box>
//                 </Grid>
//                 <Grid item xs={3}></Grid>
//                 <Grid item xs={6}>
//                     <div class="meetingName1">
//                         <h6>Choose your audio and video settings for</h6>
//                         <h4>Development Process</h4>
//                     </div>
//                 </Grid>
//                 <Grid item xs={3}></Grid>
//                 <Grid item xs={3}></Grid>
//                 <Grid item xs={6}>
//                     <div class="innerBox">
//                         <Grid container direction="row" justify="center" alignItems="center">
//                             <Grid item xs={11}></Grid>
//                             <Grid item xs={1}>
//                                 <Box mt={2}>
//                                     <InfoIcon/>
//                                 </Box>
//                             </Grid>
//                             <Grid item xs={4}></Grid>
//                             <Grid item xs={4}>
//                                 <Box mt={4} className={classes.userImageBox}>
//                                     <AccountCircleIcon className={classes.userIcon}/>
//                                 </Box>
//                             </Grid>
//                             <Grid item xs={4}></Grid>
                            
//                             <Grid item xs={3}></Grid>
//                             <Grid item xs={2}>
//                                 <Box ml={2} mr={2} mt={5} className={classes.iconBox}><VideocamIcon className={classes.icon}/></Box>
//                             </Grid>
//                             <Grid item xs={2}>
//                                 <Box ml={2} mr={2} mt={5} className={classes.iconBox}><MicIcon className={classes.icon}/></Box>
//                             </Grid>
//                             <Grid item xs={2}>
//                                 <Box ml={2} mr={2} mt={5} className={classes.iconBox}><VolumeUpIcon className={classes.icon}/></Box>
//                             </Grid>
//                             <Grid item xs={3}></Grid>
//                             <Grid item xs={3}></Grid>
//                             <Grid item xs={2}>
//                                 <Box ml={2} mr={2} mt={2} className={classes.iconText}>Camera is on</Box>
//                             </Grid>
//                             <Grid item xs={2}>
//                                 <Box ml={2} mr={2} mt={2} className={classes.iconText}>Mic is on</Box>
//                             </Grid>
//                             <Grid item xs={2}>
//                                 <Box ml={2} mr={2} mt={2} className={classes.iconText}>Audio is on</Box>
//                             </Grid>
//                             <Grid item xs={3}></Grid>
//                             <Grid item xs={5}></Grid>
//                             <Grid item xs={2}>
//                                 <Box className={classes.iconBox} mt={4}>
//                                     <Button className={classes.join} onClick={changeState}>Join</Button>
//                                 </Box>
//                             </Grid>
//                             <Grid item xs={5}></Grid>
//                         </Grid>
//                     </div>
//                 </Grid>
//                 <Grid item xs={3}></Grid>
                
                
//             </Grid>
//         </div>
//     )
// }
class Waiting extends Component {
    constructor(props) {
        super(props);
        // this.OPENVIDU_SERVER_URL = 'https://' + window.location.hostname + ':4443';
        this.OPENVIDU_SERVER_URL = 'https://conference.letschase.io/';
        this.OPENVIDU_SERVER_SECRET = 'Invente@Open123#';
        console.log(props.location);
        this.state = {
            mySessionId: props.location.sessionName.mySessionId ? props.location.sessionName.mySessionId : 'SessionA',
            myUserName: props.location.user.myUserName ? props.location.user.myUserName : 'Chase_User' + Math.floor(Math.random() * 100),
            myMeetingName: props.location.meetingName.myMeetingName ? props.location.meetingName.myMeetingName : 'TrialClass',
            token: undefined,
            mic: true,
            vid: true,
            audio: false,
        };

        this.handlerJoinSessionEvent = this.handlerJoinSessionEvent.bind(this);
        this.handlerLeaveSessionEvent = this.handlerLeaveSessionEvent.bind(this);
        this.handlerErrorEvent = this.handlerErrorEvent.bind(this);
        this.joinSession = this.joinSession.bind(this);
        this.camStatusChanged = this.camStatusChanged.bind(this);
        this.micStatusChanged = this.micStatusChanged.bind(this);
        this.audioStatusToggle = this.audioStatusToggle.bind(this);
    }

    componentDidMount() {
        localUser.setVideoActive(true);
        localUser.setAudioActive(true);
        this.setState({ localUser: localUser});
    }
    

    camStatusChanged() {
        console.log(localUser.isVideoActive());
        console.log(localUser);
        localUser.setVideoActive(!localUser.isVideoActive());
        this.setState({ localUser: localUser });
        console.log(localUser.isVideoActive());
    }

    micStatusChanged() {
        console.log(localUser.isAudioActive());
        localUser.setAudioActive(!localUser.isAudioActive());
        this.setState({ localUser: localUser });
        console.log(localUser.isAudioActive());
    }

    audioStatusToggle() {
        this.setState({audio: !this.state.audio})
    }


    handlerJoinSessionEvent() {
        console.log('Join session');
    }

    handlerLeaveSessionEvent() {
        console.log('Leave session');
        this.setState({
            session: undefined,
        });
    }

    handlerErrorEvent() {
        console.log('Leave session');
    }


    joinSession(event) {
        if (this.state.mySessionId && this.state.myUserName && this.state.myMeetingName) {
            this.getToken().then((token) => {
                this.setState({
                    token: token,
                    session: true,
                });
            });
            event.preventDefault();
        }
    }
    render() {
        const mySessionId = this.state.mySessionId;
        const myUserName = this.state.myUserName;
        const myMeetingName = this.state.myMeetingName;
        const token = this.state.token;
        // const classes = useStyles();
        const { classes } = this.props;
        var i_mic = true;
        var i_vid = true;
        return (
            <div>
                {this.state.session === undefined ? ( 
 
                <div class="app">
                <Grid container>
                    <Grid item xs={10}></Grid>
                    <Grid item xs={2}>
                        <Box mt={5}><Link to="/"><Button variant="outlined" className={classes.closeButton} >Close</Button></Link></Box>
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={6}>
                        <div class="meetingName1">
                            <h6>Choose your audio and video settings for</h6>
                            <h4>{this.state.myMeetingName}</h4>
                        </div>
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={6}>
                        <div class="innerBox">
                            <Grid container direction="row" justify="center" alignItems="center">
                                <Grid item xs={11}></Grid>
                                <Grid item xs={1}>
                                    <Box mt={2}>
                                        <InfoIcon/>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}></Grid>
                                <Grid item xs={4}>
                                    <Box mt={4} className={classes.userImageBox}>
                                        <AccountCircleIcon className={classes.userIcon}/>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}></Grid>
                                
                                <Grid item xs={3}></Grid>
                                <Grid item xs={2}>
                                    <Box ml={2} mr={2} mt={5} className={classes.iconBox}>
                                        {/* <VideocamIcon className={classes.icon}/> */}
                                        <IconButton color="inherit" className={classes.icon} onClick={this.camStatusChanged}>
                                            {localUser !== undefined && localUser.isVideoActive() ? (
                                                <Videocam />
                                            ) : (
                                                <VideocamOff color="secondary" />
                                            )}
                                        </IconButton>
                                    </Box>
                                </Grid>
                                <Grid item xs={2}>
                                    <Box ml={2} mr={2} mt={5} className={classes.iconBox}>
                                        <IconButton color="inherit" className={classes.icon} onClick={this.micStatusChanged}>
                                                {localUser !== undefined && localUser.isAudioActive() ? <Mic /> : <MicOff color="secondary" />}
                                        </IconButton>
                                        {/* <MicIcon className={classes.icon}/> */}
                                    </Box>
                                </Grid>
                                <Grid item xs={2}>
                                    <Box ml={2} mr={2} mt={5} className={classes.iconBox}>
                                        <IconButton color="inherit" className={classes.icon} onClick={this.audioStatusToggle}>
                                                {localUser !== undefined && !this.state.audio ? <VolumeUpIcon /> : <VolumeOffIcon color="secondary" />}
                                        </IconButton>
                                        {/* <VolumeUpIcon className={classes.icon}/> */}
                                    </Box>
                                </Grid>
                                <Grid item xs={3}></Grid>
                                <Grid item xs={3}></Grid>
                                <Grid item xs={2}>
                                {localUser !== undefined && localUser.isVideoActive() ? (
                                    <Box ml={2} mr={2} mt={2} className={classes.iconText}>Camera is on</Box>
                                ) : (
                                    <Box ml={2} mr={2} mt={2} className={classes.iconText}>Camera is off</Box>
                                )}
                                </Grid>
                                <Grid item xs={2}>
                                {localUser !== undefined && localUser.isAudioActive() ? (
                                    <Box ml={2} mr={2} mt={2} className={classes.iconText}>Mic is on</Box>
                                ) : (
                                    <Box ml={2} mr={2} mt={2} className={classes.iconText}>Mic is off</Box>
                                )}
                                    {/* <Box ml={2} mr={2} mt={2} className={classes.iconText}>Mic is on</Box> */}
                                </Grid>
                                <Grid item xs={2}>
                                    {localUser !==undefined && !this.state.audio ? (
                                        <Box ml={2} mr={2} mt={2} className={classes.iconText}>Audio is on</Box>
                                    ) : (
                                        <Box ml={2} mr={2} mt={2} className={classes.iconText}>Audio is off</Box>
                                    )}
                                    {/* <Box ml={2} mr={2} mt={2} className={classes.iconText}>Audio is on</Box> */}
                                </Grid>
                                <Grid item xs={3}></Grid>
                                <Grid item xs={5}></Grid>
                                <Grid item xs={2}>
                                    <Box className={classes.iconBox} mt={4}>
                                    {/* <form class="login-form" onSubmit={this.joinSession}>
                                    <button type="submit" class="btn btn-primary">Join</button>
                                    </form> */}
                                        <Button className={classes.join} onClick={this.joinSession}>Join</Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={5}></Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={3}></Grid>
                    
                    
                </Grid>
                </div>
                ):(
                    <div id="session">
                    <VideoRoom
                        id="opv-session"
                        sessionName={mySessionId}
                        user={myUserName}
                        meetingName={myMeetingName}
                        token={token}
                        joinSession={this.handlerJoinSessionEvent}
                        leaveSession={this.handlerLeaveSessionEvent}
                        error={this.handlerErrorEvent}
                        initialMic = {localUser.isAudioActive()}
                        initialVideo = {localUser.isVideoActive()}
                        initialAudio = {this.state.audio}
                    /> 
                    </div>
    
                )}
                
            </div>
        )
    }
    getToken() {
        return this.createSession(this.state.mySessionId)
            .then((sessionId) => this.createToken(sessionId))
            .catch((Err) => console.error(Err));
    }

    createSession(sessionId) {
        return new Promise((resolve, reject) => {
            var data = JSON.stringify({ customSessionId: sessionId });
            axios
                .post(this.OPENVIDU_SERVER_URL + '/api/sessions', data, {
                    headers: {
                        Authorization: 'Basic ' + btoa('OPENVIDUAPP:' + this.OPENVIDU_SERVER_SECRET),
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    console.log('CREATE SESION', response);
                    resolve(response.data.id);
                })
                .catch((response) => {
                    var error = Object.assign({}, response);
                    if (error.response && error.response.status === 409) {
                        resolve(sessionId);
                    } else {
                        console.log(error);
                        console.warn(
                            'No connection to OpenVidu Server. This may be a certificate error at ' + this.OPENVIDU_SERVER_URL,
                        );
                        if (
                            window.confirm(
                                'No connection to OpenVidu Server. This may be a certificate error at "' +
                                    this.OPENVIDU_SERVER_URL +
                                    '"\n\nClick OK to navigate and accept it. ' +
                                    'If no certificate warning is shown, then check that your OpenVidu Server is up and running at "' +
                                    this.OPENVIDU_SERVER_URL +
                                    '"',
                            )
                        ) {
                            window.location.assign(this.OPENVIDU_SERVER_URL + '/accept-certificate');
                        }
                    }
                });
        });
    }

    createToken(sessionId) {
        return new Promise((resolve, reject) => {
            var data = JSON.stringify({ session: sessionId });
            axios
                .post(this.OPENVIDU_SERVER_URL + '/api/tokens', data, {
                    headers: {
                        Authorization: 'Basic ' + btoa('OPENVIDUAPP:' + this.OPENVIDU_SERVER_SECRET),
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    console.log('TOKEN', response);
                    resolve(response.data.token);
                })
                .catch((error) => reject(error));
        });
    }
}

export default withStyles(styles)(Waiting);