import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import VideoRoomComponent from './components/VideoRoomComponent';
import Join from './Join'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Waiting from './Waiting'
import WaitingFunction from './WaitingFuntion'
import JoinFunction from './JoinFunction'
import { GET_ACCESS_TOKEN } from './config';
import loadingIcon from './assets/images/eclipse-loading.gif';
import Axios from 'axios';
import qs from 'querystring';
import { useSnackbar } from 'notistack';

function App() {
	const { enqueueSnackbar } = useSnackbar();

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		getAccessToken();
	}, []);

	const getAccessToken = () => {
		const postObj = {
			grant_type: 'client_credentials',
			client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
			client_secret: process.env.REACT_APP_KEYCLOAK_CLIENT_SECRET
		}
		const realm = process.env.REACT_APP_KEYCLOAK_REALM;
		setLoading(true);
		Axios
			.post(`${GET_ACCESS_TOKEN(realm)}`, qs.stringify(postObj), { headers: { "Content-Type": "application/x-www-form-urlencoded;charset=utf-8" } })
			.then(response => {
				if (response.data) {
					localStorage.setItem('access_token', response.data.access_token);
					setLoading(false);
				}
			})
			.catch(err => {
				if (err.response) {
					enqueueSnackbar((err.response.data && err.response.data.error_description) ? err.response.data.error_description : 'Failed to fetch Survey details, please try again.', {
						variant: 'error',
						preventDuplicate: true
					});
				}
				setLoading(false);
			});
	}

	if (!loading) {
		return (
			<div>

				<Router>
					<Switch>
						{/* <Route exact path="/" component={Join}></Route> */}
						<Route exact path="/:meetingCode/:nickName/meet" render={(props) => <WaitingFunction {...props} setLoading={setLoading} />}></Route>
						<Route exact path="/:meetingCode" render={(props) => <JoinFunction {...props} setLoading={setLoading} />}></Route>
						<Route exact path="/" render={(props) => <JoinFunction {...props} setLoading={setLoading} />}></Route>

						{/* <Route exact path="/waiting" component={Waiting}></Route> */}
						<Redirect to="/" />
					</Switch>
				</Router>
			</div>
		)
	}
	else {
		return (
			<div style={{
				height: '100vh',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				backgroundColor: '#f1f2f3',
				width: '100vw',
				fontFamily: 'Poppins'
			}}>
				{/* <h1>Loading.....</h1> */}
				<p><img src={loadingIcon} /></p>
			</div >
		)
	}
}

export default App
