import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { GET_MEETING_DETAILS } from './config';
import { useSnackbar } from 'notistack';
import { useTheme } from '@material-ui/core';
// import group from './assets/images/group.png';
// import people1 from './assets/images/people-1.svg';
// import people2 from './assets/images/people-2.svg';
// import logo from './assets/images/logo.svg';
import logo from './assets/images/app-logo.png';
import brandingImage from './assets/images/Wavy_Tech-08_Singleverted.png';

export const getMeetingDetails = (meetingCode) => {
	return new Promise((resolve, reject) => {
		Axios
			.get(GET_MEETING_DETAILS(meetingCode), { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				// if (error.response && error.response.status === 409) {
				// 	resolve(sessionId);
				// } else {
				// console.log('GET_MEETING_DETAILS error ', error);
				reject(error);
				// }
			});
	});
}

function JoinFunction(props) {
	const { enqueueSnackbar } = useSnackbar();
	const theme = useTheme();

	const [mySessionId, setmySessionId] = useState(props.match.params.meetingCode || '');
	const [myMeetingName, setmyMeetingName] = useState('');
	const [myUserName, setmyUserName] = useState('');
	// const [myUserName, setmyUserName] = useState('Chase_User_' + Math.floor(Math.random() * 100));

	const handleChangeSessionId = (e) => {
		setmySessionId(e.target.value);
	}

	const handleChangeUserName = (e) => {
		setmyUserName(e.target.value);
	}

	const handleChangeMeetingName = (e) => {
		setmyMeetingName(e.target.value);
	}

	const handleJoinClick = (e) => {
		if (!myUserName) {
			enqueueSnackbar('Please Enter Your Name.', {
				variant: 'error',
				preventDuplicate: true
			});
		}
		else if (!mySessionId) {
			enqueueSnackbar('Please Enter Meeting code.', {
				variant: 'error',
				preventDuplicate: true
			});
		}
		else {
			props.setLoading(true);
			getMeetingDetails(mySessionId)
				.then((response) => {
					console.log('GET_MEETING_DETAILS ', response);
					if (response && response.meeting_code)
						props.history.push(`/${response.meeting_code}/${myUserName}/meet`);
					else
						enqueueSnackbar((response && response.message) ? response.message : 'Invalid Meeting Code.', {
							variant: 'error',
							preventDuplicate: true
						});
					props.setLoading(false);
				})
				.catch((error) => {
					console.log('GET_MEETING_DETAILS error ', error.response);
					enqueueSnackbar((error.response && error.response.data && error.response.data.error_description) ? error.response.data.error_description : 'Failed to Validate Meeting Code.', {
						variant: 'error',
						preventDuplicate: true
					});
					props.setLoading(false);
				})
		}
	}

	return (
		<>
			<div class="row primary-logo ml-lg-25">
				<img height="48px" width="43px" src={logo} alt="logo" />
				<p>Leagues.ai</p>
			</div>
			<div className="row justify-content-center">
				<div className="col-sm-12 col-md-12 col-lg-7 description">
					<img height="auto" width="75%" src={brandingImage} alt="..." />
					<p class="info">Business Growth, Powered by Sports Leagues</p>
				</div>
				<div className="col-sm-12 col-md-12 col-lg-5 p-0 pt-5 pt-lg-0 d-flex align-items-center row justify-content-center">
					<div class="row justify-content-center px-3 px-lg-5 ">
						<div class="col-lg-12">

							{/* <img className="logo" src={logo} className="img-fluid" alt="chase-logo" /> */}
							<h2 className="login-title text-center text-lg-left">Join Meeting</h2>
							{/* <form className="login-form" onSubmit={this.joinSession}> */}
							<form className="login-form">
								<div className="form-group">
									<label >Name</label>
									<input className="form-control"
										placeholder="Enter Your Name"
										aria-describedby="username"
										type="text"
										id="userName"
										value={myUserName}
										onChange={handleChangeUserName}
										required />
								</div>
								{/* <div className="form-group">
							<label for="email">Meeting Name</label>
							<input className="form-control"
								aria-describedby="meetingName"
                                type="text"
                                id="meetinName"
                                value={myMeetingName}
                                onChange={handleChangeMeetingName}
                                required/>
						</div> */}
								<div className="form-group">
									<label>Meeting Code</label>
									<input
										placeholder="Enter Meeting code"
										type="text"
										id="sessionId"
										value={mySessionId}
										onChange={handleChangeSessionId}
										className="form-control"
										required />
								</div>
								{/* <Link to={{
                            pathname: `/${mySessionId}/meet`,
                            sessionName:{mySessionId},
                            user:{myUserName},
                            meetingName:{myMeetingName},
                        }}> */}
								<button onClick={(e) => handleJoinClick(e)} className="mt-3">Join</button>
								{/* <input name="commit" style={{ width: 80, backgroundColor: theme.palette.primary.main, color: '#FFF' }} type="button" onClick={(e) => handleJoinClick(e)} className="btn" value="Join" /> */}
								{/* </Link> */}
							</form>
						</div>
					</div>
					{/* <div className="copy-right">
						<p><span className="copy-right-icon">&copy;</span> 2020 Invente | Terms of
					Service | Privacy Policy</p>
					</div> */}
				</div>
			</div>
			<div class="row justify-content-center copy-center-text lfooter">© 2021 Leagues.ai All rights reserved</div>
		</>
	)
}

export default JoinFunction
