import { createMuiTheme } from '@material-ui/core/styles';

const primaryMain = '#8A2BE2';
const primaryLight = '#FFF5D5';
const secondaryMain = '#EEEEEE';
const secondaryLight = '#F7F7F7';


export const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      // main: '#F77D1D',
      // light: '#FFFAE9',
      light: primaryLight,
      main: primaryMain,
      // main: '#FFCDEE',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: secondaryMain,
      light: secondaryLight,
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        borderRadius: '0px'
      }
    },
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: primaryLight,
          '&:hover':{
            backgroundColor: 'rgba(255, 245, 213, 0.7)'
          }
        }
      },
      button: {
        '&:hover': {
          // backgroundColor: '#fff5d5b3',
          // In RGBA
          backgroundColor: 'rgba(255, 245, 213, 0.7)'
        }
      },
    },
    MuiAvatar: {
      root: {
        textTransform: 'uppercase'
      }
    },
    MuiButton: {
      root: {
        textTransform: 'capitalize'
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        }
      }
    }
  }
});