import 'react-app-polyfill/ie11';
import "react-app-polyfill/stable";
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App'
import registerServiceWorker from './registerServiceWorker';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@material-ui/core';
import { theme } from './theme';


const defaultNotificationProps = {
  maxSnack: 3,
  autoHideDuration: 5000,
  variant: 'info',
  anchorOrigin: { horizontal: 'right', vertical: 'bottom' }
}

ReactDOM.render(
  <SnackbarProvider {...defaultNotificationProps} maxSnack={3}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </SnackbarProvider>
  , document.getElementById('root')

);
registerServiceWorker();
