import React, { useState, useEffect } from 'react'
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import "./WaitingStyle.css"
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
// import InfoIcon from '@material-ui/icons/Info';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import VideocamIcon from '@material-ui/icons/Videocam';
import MicIcon from '@material-ui/icons/Mic';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VideoRoom from './components/VideoRoomComponent';
import { Link } from "react-router-dom";
import axios from 'axios';
import UserModel from './models/user-model';
import IconButton from '@material-ui/core/IconButton';
import Mic from '@material-ui/icons/Mic';
import MicOff from '@material-ui/icons/MicOff';
import Videocam from '@material-ui/icons/Videocam';
import VideocamOff from '@material-ui/icons/VideocamOff';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import { getMeetingDetails } from './JoinFunction'
import { useSnackbar } from 'notistack';
import { ReactComponent as InfoIcon } from "./assets/icons/info-icon.svg";

var localUser1 = new UserModel();
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#373644',
        height: '100vh',
        overflowY: 'auto'
    },
    closeButton: {
        textTransform: 'capitalize',
        fontWeight: 'normal',
        color: "#ffffffb3",
        border: '1px solid #ffffffb3',
        width: 100,
        margin: '20px 30px 0',
        [theme.breakpoints.down('xs')]: {
            margin: '20px 10px 0',
            paddingRight: 0
        }
    },
    detailsRoot: {
        color: '#ffffffcc',
        textAlign: 'center',
    },
    meetingName: {
        fontSize: 22,
        fontWeight: 600,
        marginTop: 20
    },
    previewBox: {
        backgroundColor: '#292828',
        borderRadius: 10,
        color: '#ffffffcc',
        width: '50%',
        margin: '0 auto',
        [theme.breakpoints.down('xs')]: {
            width: '90%'
        }
    },
    userIcon: {
        height: '9rem',
        width: '9rem',
        color: "gray"
    },
    actionsRoot: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 50
    },
    actionButtonRoot: {
        width: 100,
        '& p': {
            fontSize: 12
        }
    },
    icon: {
        height: 58,
        width: 58,
        color: "white",
        backgroundColor: "#3f3e3e",
        borderRadius: 6,
        padding: '10px',
        '&:hover': {
            backgroundColor: '#3f3e3e80'
        }
    },
    button: {
        margin: '33px 7px',
        width: 140,
        fontSize: 17,
        '&:hover': {
            boxShadow: 'none'
        },
    },
    infoIcon: {
        '& svg': {
            '& path': {
                fill: '#FFF'
            }
        }
    }
}));

const WaitingFuntion = (props) => {
    const classes = useStyles(props);
    const { enqueueSnackbar } = useSnackbar();

    const [mySessionId, setmySessionId] = useState(props.match.params.meetingCode ? props.match.params.meetingCode : 'SessionA');
    const [myUserName, setmyUserName] = useState(props.match.params.nickName ? props.match.params.nickName : 'Chase_User' + Math.floor(Math.random() * 100));
    const [myMeetingName, setmyMeetingName] = useState('Chase Meeting');
    const [token, settoken] = useState(undefined);
    const [vid, setvid] = useState(true);
    const [mic, setmic] = useState(true);
    const [audio, setaudio] = useState(true);
    const [localUser, setlocalUser] = useState(localUser1);
    const [session, setsession] = useState(undefined)
    // const OPENVIDU_SERVER_URL = 'https://' + window.location.hostname + ':4443';
    // const OPENVIDU_SERVER_SECRET = 'MY_SECRET';
    const OPENVIDU_SERVER_URL = 'https://conference.letschase.io';
    const OPENVIDU_SERVER_SECRET = 'Invente@Open123#';

    useEffect(() => {
        getMeetingDetails(mySessionId)
            .then((response) => {
                console.log('GET_MEETING_DETAILS ', response);
                if (response) {
                    setmyMeetingName(response.title);
                }
                props.setLoading(false);
            })
            .catch((error) => {
                console.log('GET_MEETING_DETAILS error ', error.response);
                enqueueSnackbar((error.response && error.response.data && error.response.data.error_description) ? error.response.data.error_description : 'Failed to Validate Meeting Code.', {
                    variant: 'error',
                    preventDuplicate: true
                });
                props.setLoading(false);
            })

        localUser.setVideoActive(false);
        setvid(false);
        localUser.setAudioActive(true);
        setlocalUser(localUser);
    }, []);

    const camStatusChanged = () => {
        console.log(localUser.isVideoActive());
        console.log(localUser);
        localUser.setVideoActive(!localUser.isVideoActive());
        setvid(!vid);
        setlocalUser(localUser);
        console.log(localUser.isVideoActive());
    }

    const micStatusChanged = () => {
        console.log(localUser.isAudioActive());
        localUser.setAudioActive(!localUser.isAudioActive());
        setlocalUser(localUser);
        setmic(!mic);
        console.log(localUser.isAudioActive());
    }

    const audioStatusToggle = () => {
        setaudio(!audio);
    }


    const handlerJoinSessionEvent = () => {
        console.log('Join session');
    }

    const handlerLeaveSessionEvent = () => {
        console.log('Leave session');
        setsession(undefined);
        props.history.push(`/${mySessionId}`);
        window.location.reload();
    }

    const handlerErrorEvent = () => {
        console.log('Leave session');
    }


    const joinSession = (event) => {
        if (mySessionId && myUserName && myMeetingName) {
            getToken().then((token) => {
                settoken(token);
                setsession(true);
            });
            event.preventDefault();
        }
    }

    const getToken = () => {
        return createSession(mySessionId)
            .then((sessionId) => createToken(sessionId))
            .catch((Err) => console.error(Err));
    }

    const createSession = (sessionId) => {
        return new Promise((resolve, reject) => {
            var data = JSON.stringify({ customSessionId: sessionId });
            axios
                .post(OPENVIDU_SERVER_URL + '/api/sessions', data, {
                    headers: {
                        Authorization: 'Basic ' + btoa('OPENVIDUAPP:' + OPENVIDU_SERVER_SECRET),
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    console.log('CREATE SESION', response);
                    resolve(response.data.id);
                })
                .catch((response) => {
                    var error = Object.assign({}, response);
                    if (error.response && error.response.status === 409) {
                        resolve(sessionId);
                    } else {
                        console.log(error);
                        console.warn(
                            'No connection to OpenVidu Server. This may be a certificate error at ' + OPENVIDU_SERVER_URL,
                        );
                        if (
                            window.confirm(
                                'No connection to OpenVidu Server. This may be a certificate error at "' +
                                OPENVIDU_SERVER_URL +
                                '"\n\nClick OK to navigate and accept it. ' +
                                'If no certificate warning is shown, then check that your OpenVidu Server is up and running at "' +
                                OPENVIDU_SERVER_URL +
                                '"',
                            )
                        ) {
                            window.location.assign(OPENVIDU_SERVER_URL + '/accept-certificate');
                        }
                    }
                });
        });
    }

    const createToken = (sessionId) => {
        return new Promise((resolve, reject) => {
            var data = JSON.stringify({ session: sessionId });
            axios
                .post(OPENVIDU_SERVER_URL + '/api/tokens', data, {
                    headers: {
                        Authorization: 'Basic ' + btoa('OPENVIDUAPP:' + OPENVIDU_SERVER_SECRET),
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    console.log('TOKEN', response);
                    resolve(response.data.token);
                })
                .catch((error) => reject(error));
        });
    }


    return (
        <div>
            {session === undefined ? (

                <div className={classes.root}>
                    <div style={{ textAlign: 'right' }}>
                        <Button variant="outlined" className={classes.closeButton} onClick={() => { props.history.push(`/${mySessionId}`); window.location.reload(); }}>Close</Button>
                    </div>
                    <div className={classes.detailsRoot}>
                        <p>Choose your audio and video settings for</p>
                        <p className={classes.meetingName}>{myMeetingName}</p>

                        <div className={classes.previewBox}>
                            <div style={{ textAlign: 'right', padding: '10px 20px' }}>
                                {/* <InfoIcon style={{ cursor: 'pointer' }} /> */}
                                <IconButton className={classes.infoIcon} size="small" ><InfoIcon width="17" height="17" /></IconButton>
                            </div>
                            <AccountCircleIcon className={classes.userIcon} />
                            <div className={classes.actionsRoot}>
                                <div className={classes.actionButtonRoot}>
                                    <IconButton disabled color="inherit" className={classes.icon} onClick={camStatusChanged}>
                                        {localUser !== undefined && vid ?
                                            <Videocam />
                                            : <VideocamOff color="secondary" />
                                        }
                                    </IconButton>
                                    <p>{localUser.isVideoActive() ? 'Camera is on' : 'Camera is off'}</p>
                                </div>
                                <div className={classes.actionButtonRoot}>
                                    <IconButton color="inherit" className={classes.icon} onClick={micStatusChanged}>
                                        {localUser !== undefined && mic ? <Mic /> : <MicOff color="secondary" />}
                                    </IconButton>
                                    <p>{localUser.isAudioActive() ? 'Mic is on' : 'Mic is off'}</p>
                                </div>

                                <div className={classes.actionButtonRoot}>
                                    <IconButton color="inherit" className={classes.icon} onClick={audioStatusToggle}>
                                        {localUser !== undefined && audio ? <VolumeUpIcon /> : <VolumeOffIcon color="secondary" />}
                                    </IconButton>
                                    <p>{audio ? 'Audio is on' : 'Audio is off'}</p>
                                </div>
                            </div>
                            <Button variant="contained" color="primary" size="medium" className={classes.button} onClick={joinSession}>Join now</Button>

                        </div>
                    </div>
                </div >
            ) : (
                    <div id="session">
                        <VideoRoom
                            id="external-session"
                            sessionName={mySessionId}
                            user={myUserName}
                            meetingName={myMeetingName}
                            token={token}
                            joinSession={handlerJoinSessionEvent}
                            leaveSession={handlerLeaveSessionEvent}
                            error={handlerErrorEvent}
                            initialMic={localUser.isAudioActive()}
                            initialVideo={localUser.isVideoActive()}
                            initialAudio={audio}
                        />
                    </div>

                )}

        </div>

    )
}

export default WaitingFuntion
