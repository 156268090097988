import React, { Component } from 'react';
import { Link } from 'react-router-dom';


class Join extends Component {
    constructor(props) {
        super(props);
        // this.OPENVIDU_SERVER_URL = 'https://' + window.location.hostname + ':4443';
        // this.OPENVIDU_SERVER_SECRET = 'MY_SECRET';
        this.state = {
            mySessionId: 'SessionA',
            myUserName: 'Chase_User_' + Math.floor(Math.random() * 100),
            myMeetingName: 'TrialClass',
            // token: undefined,
            // open: false
        };

        // this.handlerJoinSessionEvent = this.handlerJoinSessionEvent.bind(this);
        // this.handlerLeaveSessionEvent = this.handlerLeaveSessionEvent.bind(this);
        // this.handlerErrorEvent = this.handlerErrorEvent.bind(this);
        this.handleChangeSessionId = this.handleChangeSessionId.bind(this);
        this.handleChangeUserName = this.handleChangeUserName.bind(this);
        this.handleChangeMeetingName = this.handleChangeMeetingName.bind(this);
        // this.joinSession = this.joinSession.bind(this);
    }


    // handlerJoinSessionEvent() {
    //     console.log('Join session');
    // }

    // handlerLeaveSessionEvent() {
    //     console.log('Leave session');
    //     this.setState({
    //         session: undefined,
    //     });
    // }

    // handlerErrorEvent() {
    //     console.log('Leave session');
    // }

    handleChangeSessionId(e) {
        this.setState({
            mySessionId: e.target.value,
        });
    }

    handleChangeUserName(e) {
        this.setState({
            myUserName: e.target.value,
        });
    }

    handleChangeMeetingName(e) {
        this.setState({
            myMeetingName: e.target.value,
        });
    }

    // leaveWaitingSession(open){
    //     this.setState({
    //         open: true
    //     })
    // }

    // joinSession(event) {
    //     if (this.state.mySessionId && this.state.myUserName && this.state.myMeetingName) {
    //         this.getToken().then((token) => {
    //             this.setState({
    //                 token: token,
    //                 session: true,
    //             });
    //         });
    //         event.preventDefault();
    //     }
    // }
    render() {
        const mySessionId = this.state.mySessionId;
        const myUserName = this.state.myUserName;
        const myMeetingName = this.state.myMeetingName;
        // const token = this.state.token;
        // var i_mic = true;
        // var i_vid = false;
        return (
            // <div>
                /* {this.state.session === undefined && !this.state.open ? ( */
            // <div>
            <div class="row justify-content-center">
		<div class="col-sm-12 col-md-12 col-lg-6 description">
			<div>
				<div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel">
					<ol class="carousel-indicators">
						<li data-target="#carouselExampleCaptions" data-slide-to="0" class="active"></li>
						<li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
						<li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
					</ol>
					<div class="carousel-inner">
						<div class="carousel-item active">
							<img class="image" height="250px"  width="250px"src="images/Group 362.png"   class="d-block w-100 " alt="..."
							  />
							<div class="carousel-caption d-none d-md-block">
								<div class="text-content text-dark ">
									<strong class="scroll-title">
										<span class="title-1 active">Interact</span>
										<svg class="bi bi-dot svg-dot" width="1em" height="1em" viewBox="0 0 16 16"
											fill="currentColor">
											<path fill-rule="evenodd" d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
										</svg>
										<span class="title-2 " >Inspire</span>
										<svg class="bi bi-dot svg-dot" width="1em" height="1em" viewBox="0 0 16 16"
											fill="currentColor">
											<path fill-rule="evenodd" d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
										</svg>
										<span class="title-3 ">Innovate</span>
									</strong>
									<p class="scroll-text">Engage with your colleagues,<br/> share your inspirations and unleash innovation.

									</p>
									<p class="scroll-textnew">Conversations like never before!</p>
								</div>
							</div>
						</div>
						<div class="carousel-item">
							<img  height="250px"  width="250px" src="images/people.svg" class="d-block w-100" alt="..."/>
							<div class="carousel-caption d-none d-md-block">
								<div class="text-content text-dark ">
									<strong class="scroll-title">
										<span class="title-1 active">Enpower</span>
										<svg class="bi bi-dot svg-dot" width="1em" height="1em" viewBox="0 0 16 16"
											fill="currentColor">
											<path fill-rule="evenodd" d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
										</svg>
										<span class="title-2 ">Excel</span>
										<svg class="bi bi-dot svg-dot" width="1em" height="1em" viewBox="0 0 16 16"
											fill="currentColor">
											<path fill-rule="evenodd" d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
										</svg>
										<span class="title-3 ">Experience</span>
									</strong>
									<p class="scroll-text">Learn from your peers, enable business growth<br/> and practise leadership.</p>
									<p class="scroll-textnew">Growth like never before!</p>
								</div>
							</div>
						</div>
						<div class="carousel-item">
							<img  height="250px"  width="250px"src="images/people7.svg" class="d-block w-100" alt="..."/>
							<div class="carousel-caption d-none d-md-block">
								<div class="text-content text-dark ">
									<strong class="scroll-title">
										<span class="title-1 active">Challenge</span>
										<svg class="bi bi-dot svg-dot" width="1em" height="1em" viewBox="0 0 16 16"
											fill="currentColor">
											<path fill-rule="evenodd" d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
										</svg>
										<span class="title-2 ">Compete</span>
										<svg class="bi bi-dot svg-dot" width="1em" height="1em" viewBox="0 0 16 16"
											fill="currentColor">
											<path fill-rule="evenodd" d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
										</svg>
										<span class="title-3 ">Conquer</span>
									</strong>
									<p class="scroll-text">Be agile, stay ahead of your peers<br/> and win exceptional rewards.</p>
									<p class="scroll-textnew">Fun like never before!</p>
								</div>
							</div>
						</div>
					</div>
					
				</div>
			</div>
		</div>
		<div class="col-sm-12 col-md-12 col-lg-6 p-0">
			<div class="login-page">
				<div>
					<img class="logo" src="images/logo.svg" class="img-fluid" alt="chase-logo"/>
					<h2 class="login-title">Join Video Call</h2>
					{/* <form class="login-form" onSubmit={this.joinSession}> */}
                    <form class="login-form">
						<div class="form-group">
							<label for="email">User</label>
							<input class="form-control"
								aria-describedby="username"
                                type="text"
                                id="userName"
                                value={myUserName}
                                onChange={this.handleChangeUserName}
                                required/>
						</div>
                        <div class="form-group">
							<label for="email">Meeting Name</label>
							<input class="form-control"
								aria-describedby="meetingName"
                                type="text"
                                id="meetinName"
                                value={myMeetingName}
                                onChange={this.handleChangeMeetingName}
                                required/>
						</div>
						<div class="form-group">
							<label for="password">Session ID</label>
                            <input
                             type="text"
                             id="sessionId"
                             value={mySessionId}
                             onChange={this.handleChangeSessionId}
                             required
                             class="form-control"
                             required/>
						</div>
                        <Link to={{
                            pathname: '/waiting',
                            // id:"opv-session",
                            sessionName:{mySessionId},
                            user:{myUserName},
                            meetingName:{myMeetingName},
                            // token:{token},
                            // joinSession:{handlerJoinSessionEvent},
                            // leaveSession:{handlerLeaveSessionEvent},
                            // error:{handlerErrorEvent},
                            // initialMic : {i_mic},
                            // initialVideo : {i_vid},
                        }}>
                            {/* <button class="btn btn-primary">Join</button> */}
                            <input name="commit" type="submit" class="btn btn-warning" value="Join" />
						{/* <button type="submit" class="btn btn-primary">Join</button> */}
                        </Link>
                        {/* <input name="commit" type="submit" class="btn btn-primary" value="Join" /> */}
					</form>
				</div>
			</div>
			<div class="copy-right">
				<p><span class="copy-right-icon">&copy;</span> 2020 Invente | Terms of
					Service | Privacy Policy</p>
			</div>
		</div>
	</div>
                
            // </div>
                // ):(
                //     <div id="session">
                //         <Link exact to="/waiting">
                //         <Waiting
                //         id="opv-session"
                //         sessionName={mySessionId}
                //         user={myUserName}
                //         meetingName={myMeetingName}
                //         token={token}
                //         joinSession={this.handlerJoinSessionEvent}
                //         leaveSession={this.handlerLeaveSessionEvent}
                //         error={this.handlerErrorEvent}
                //         initialMic = {i_mic}
                //         initialVideo = {i_vid}
                //         />
                //         </Link>
                    // <VideoRoom
                    //     id="opv-session"
                    //     sessionName={mySessionId}
                    //     user={myUserName}
                    //     meetingName={myMeetingName}
                    //     token={token}
                    //     joinSession={this.handlerJoinSessionEvent}
                    //     leaveSession={this.handlerLeaveSessionEvent}
                    //     error={this.handlerErrorEvent}
                    //     initialMic = {i_mic}
                    //     initialVideo = {i_vid}
                    // /> 
                // </div>
                )}
                
            // </div>
        // )
    // }
    // getToken() {
    //     return this.createSession(this.state.mySessionId)
    //         .then((sessionId) => this.createToken(sessionId))
    //         .catch((Err) => console.error(Err));
    // }

    // createSession(sessionId) {
    //     return new Promise((resolve, reject) => {
    //         var data = JSON.stringify({ customSessionId: sessionId });
    //         axios
    //             .post(this.OPENVIDU_SERVER_URL + '/api/sessions', data, {
    //                 headers: {
    //                     Authorization: 'Basic ' + btoa('OPENVIDUAPP:' + this.OPENVIDU_SERVER_SECRET),
    //                     'Content-Type': 'application/json',
    //                 },
    //             })
    //             .then((response) => {
    //                 console.log('CREATE SESION', response);
    //                 resolve(response.data.id);
    //             })
    //             .catch((response) => {
    //                 var error = Object.assign({}, response);
    //                 if (error.response && error.response.status === 409) {
    //                     resolve(sessionId);
    //                 } else {
    //                     console.log(error);
    //                     console.warn(
    //                         'No connection to OpenVidu Server. This may be a certificate error at ' + this.OPENVIDU_SERVER_URL,
    //                     );
    //                     if (
    //                         window.confirm(
    //                             'No connection to OpenVidu Server. This may be a certificate error at "' +
    //                                 this.OPENVIDU_SERVER_URL +
    //                                 '"\n\nClick OK to navigate and accept it. ' +
    //                                 'If no certificate warning is shown, then check that your OpenVidu Server is up and running at "' +
    //                                 this.OPENVIDU_SERVER_URL +
    //                                 '"',
    //                         )
    //                     ) {
    //                         window.location.assign(this.OPENVIDU_SERVER_URL + '/accept-certificate');
    //                     }
    //                 }
    //             });
    //     });
    // }

    // createToken(sessionId) {
    //     return new Promise((resolve, reject) => {
    //         var data = JSON.stringify({ session: sessionId });
    //         axios
    //             .post(this.OPENVIDU_SERVER_URL + '/api/tokens', data, {
    //                 headers: {
    //                     Authorization: 'Basic ' + btoa('OPENVIDUAPP:' + this.OPENVIDU_SERVER_SECRET),
    //                     'Content-Type': 'application/json',
    //                 },
    //             })
    //             .then((response) => {
    //                 console.log('TOKEN', response);
    //                 resolve(response.data.token);
    //             })
    //             .catch((error) => reject(error));
    //     });
    // }
}
// }

export default Join;
