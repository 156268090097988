import React, { Component } from 'react';
import './ToolbarComponent.css';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import Mic from '@material-ui/icons/Mic';
import MicOff from '@material-ui/icons/MicOff';
import Videocam from '@material-ui/icons/Videocam';
import VideocamOff from '@material-ui/icons/VideocamOff';
import Fullscreen from '@material-ui/icons/Fullscreen';
import FullscreenExit from '@material-ui/icons/FullscreenExit';
import PictureInPicture from '@material-ui/icons/PictureInPicture';
import ScreenShare from '@material-ui/icons/ScreenShare';
import StopScreenShare from '@material-ui/icons/StopScreenShare';
import CallEndIcon from '@material-ui/icons/CallEnd';
import Tooltip from '@material-ui/core/Tooltip';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import QuestionAnswer from '@material-ui/icons/QuestionAnswer';
import { Link } from "react-router-dom";
import VolumeUp from '@material-ui/icons/VolumeUp';
import VolumeOff from '@material-ui/icons/VolumeOff';

import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

const logo = require('../../assets/images/openvidu_logo.png');

export default class ToolbarComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { fullscreen: false };
        this.camStatusChanged = this.camStatusChanged.bind(this);
        this.micStatusChanged = this.micStatusChanged.bind(this);
        this.screenShare = this.screenShare.bind(this);
        this.stopScreenShare = this.stopScreenShare.bind(this);
        this.toggleFullscreen = this.toggleFullscreen.bind(this);
        this.leaveSession = this.leaveSession.bind(this);
        this.toggleChat = this.toggleChat.bind(this);
        this.audioStatusChanged = this.audioStatusChanged.bind(this);
    }

    componentDidMount() {
        var h1 = document.getElementById("demo"),
            seconds = 0, minutes = 0, hours = 0,
            t;
        const add = () => {
            seconds++;
            if (seconds >= 60) {
                seconds = 0;
                minutes++;
                if (minutes >= 60) {
                    minutes = 0;
                    hours++;
                }
            }

            h1.innerText = (hours ? (hours > 9 ? hours : "0" + hours) : "00") + ":" + (minutes ? (minutes > 9 ? minutes : "0" + minutes) : "00") + ":" + (seconds > 9 ? seconds : "0" + seconds);

            timer();
        }
        const timer = () => {
            t = setTimeout(add, 1000);
        }
        timer();
    }

    micStatusChanged() {
        this.props.micStatusChanged();
    }

    camStatusChanged() {
        this.props.camStatusChanged();
    }
    audioStatusChanged() {
        this.props.audioStatusChanged();
    }

    screenShare() {
        this.props.screenShare();
    }

    stopScreenShare() {
        this.props.stopScreenShare();
    }

    toggleFullscreen() {
        this.setState({ fullscreen: !this.state.fullscreen });
        this.props.toggleFullscreen();
    }

    leaveSession() {
        this.props.leaveSession();
    }

    toggleChat() {
        this.props.toggleChat();
    }

    render() {
        const mySessionId = this.props.sessionId;
        const localUser = this.props.user;
        const myMeetingName = this.props.meetingName;
        // var h1 = document.getElementById("demo"),
        //     seconds = 0, minutes = 0, hours = 0,
        //     t;

        // function add() {
        //     seconds++;
        //     if (seconds >= 60) {
        //         seconds = 0;
        //         minutes++;
        //         if (minutes >= 60) {
        //             minutes = 0;
        //             hours++;
        //         }
        //     }

        //     h1.innerText = (hours ? (hours > 9 ? hours : "0" + hours) : "00") + ":" + (minutes ? (minutes > 9 ? minutes : "0" + minutes) : "00") + ":" + (seconds > 9 ? seconds : "0" + seconds);

        //     timer();
        // }
        // function timer() {
        //     t = setTimeout(add, 1000);
        // }
        // timer();
        return (
            <>
                <AppBar className="toolbar" id="header">
                    {/* <Toolbar className="toolbar"> */}
                    <div id="meeting-title">
                        <p className="title">{myMeetingName}</p>
                        <p id="demo"><time>00:00:00</time></p>
                        {/* </Toolbar> */}
                    </div>
                </AppBar>
                <div className="buttonsContent">
                    <Tooltip title={localUser !== undefined && localUser.isAudioActive() ? "Mic off" : "Mic on"}>
                        <IconButton id="navMicButton" onClick={this.micStatusChanged}>
                            {localUser !== undefined && localUser.isAudioActive() ? <Mic /> : <MicOff />}
                        </IconButton>
                    </Tooltip>

                    {/* <IconButton disabled id="navCamButton" onClick={this.camStatusChanged}>
                {localUser !== undefined && localUser.isVideoActive() ? (
                    <Videocam />
                ) : (
                    <VideocamOff  />
                )}
            </IconButton> */}
                    <Tooltip title={localUser !== undefined && this.props.muteCheck ? "Speaker off" : "Speaker on"}>
                        <IconButton id="navAudioButton" onClick={this.audioStatusChanged}>
                            {localUser !== undefined && this.props.muteCheck ? (
                                <VolumeUp />
                            ) : (
                                    <VolumeOff />
                                )}
                        </IconButton>
                    </Tooltip>
                    {
                        this.props.isScreenShareSupported &&
                        <Tooltip title={localUser !== undefined && localUser.isScreenShareActive() ? "Select screen" : "Share screen"}>
                            <IconButton onClick={this.screenShare} id="navScreenButton">
                                {localUser !== undefined && localUser.isScreenShareActive() ? <PictureInPicture /> : <ScreenShare />}
                            </IconButton>
                        </Tooltip>
                    }
                    {localUser !== undefined &&
                        localUser.isScreenShareActive() && (
                            <Tooltip title={"Stop sharing"}>
                                <IconButton onClick={this.stopScreenShare} id="navScreenButton">
                                    <StopScreenShare />
                                </IconButton>
                            </Tooltip>
                        )}

                    {/* <IconButton className="navButton" id="fullScreenButton" onClick={this.toggleFullscreen}>
                {localUser !== undefined && this.state.fullscreen ? <FullscreenExit /> : <Fullscreen />}
            </IconButton> */}
                    {/* <Link to="/" onClick={this.leaveSession}> */}
                    <Tooltip title="Leave Meeting">
                        <IconButton onClick={this.leaveSession} id="navLeaveButton"><CallEndIcon /></IconButton>
                    </Tooltip>
                    {/* </Link> */}
                </div>
            </>
        );
    }
}
